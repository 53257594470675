/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
//@import "~bootstrap/scss/bootstrap";

@import "~@angular/material/prebuilt-themes/indigo-pink.css";


// Import library functions for theme creation.

// Include non-theme styles for core.
@include mat.core();

// Define the application's custom theme
$primary: mat.define-palette(mat.$indigo-palette);
$accent:  mat.define-palette(mat.$pink-palette, A200, A100, A400);
$theme: mat.define-light-theme($primary, $accent);

// Regular (light theme)
// Include theme styles for Angular Material components
// and include theme styles for your custom components.
@include mat.all-component-themes($theme);

// Define the application's dark theme
$primary-dark: mat.define-palette(mat.$teal-palette);
$accent-dark:  mat.define-palette(mat.$teal-palette, A200, A100, A400);

// Use mat-dark-theme instead of mat-light-theme
$dark-theme: mat.define-light-theme($primary-dark, $accent-dark);

.dark {
    @include mat.all-component-themes($dark-theme);
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


:root {
  --main-color: #ecf0f1;
  --point-color: #555;
  --size: 5px;
}

.loader {
  background-color: var(--main-color);
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  align-items: center;
  align-content: center; 
  justify-content: center;  
  z-index: 100000;
}

.loader__element {
  border-radius: 100%;
  border: var(--size) solid var(--point-color);
  margin: calc(var(--size)*2);
}

.loader__element:nth-child(1) {
  animation: preloader .6s ease-in-out alternate infinite;
}
.loader__element:nth-child(2) {
  animation: preloader .6s ease-in-out alternate .2s infinite;
}

.loader__element:nth-child(3) {
  animation: preloader .6s ease-in-out alternate .4s infinite;
}

@keyframes preloader {
  100% { transform: scale(2); }
}